<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.$route.query.type == 'edit' ? $t('cip.plat.intf.serviceType.title.editHeadTitle') : $t('cip.plat.intf.serviceType.title.addHeadTitle')"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm="dataForm"
      ></form-layout>
    </el-scrollbar>
    <head-layout
      :head-btn-options="subTableheadBtnOptions"
      :head-title="$t(`cip.plat.intf.serviceType.title.interfaceCate`)"
      @head-remove="headRemove"
      @head-add-tabs="subTableAdd"
    ></head-layout>
    <grid-layout
      ref="crud"
      class="serviceType"
      :grid-row-btn="gridRowBtn"
      :table-options="subTableOption"
      :table-data="data"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @gird-handle-select-click="selectionChange"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @row-remove="rowRemove"
      @row-edit="treeMenuEdit">
    </grid-layout>
    <CommonDialog v-if="showTreeDialog" width="60%" :dialogTitle="$t(`cip.plat.intf.serviceType.title.interfaceCate`)" @cancel="showTreeDialog = false" @confirm="handleTreeNodeSave">
      <avue-form :option="treeDialogOption"
                 ref="addForm"
                 v-model="form"
                 @submit="treeNodeSave"></avue-form>
    </CommonDialog>
  </div>
</template>
<script>

import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {update, tree, getDetail, remove, update as saveOrUpdateTree} from "@/api/system/intfServiceType";
import GridLayout from "@/views/components/layout/grid-layout";
import CommonDialog from "@/components/CommonDialog";

export default {
  name: "dataLinkEdit",
  components: {
    FormLayout,
    HeadLayout,
    GridLayout,
    CommonDialog
  },
  data() {
    return {
      data: [],
      selectionList: [],
      dataTotal: null,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableLoading: false,
      showTreeDialog: false,
      subTableOption: {
        customAddBtn: true,
        addBtn: false,
        editBtn: false,
        rowKey: 'id',
        rowParentKey: 'parentId',
        menuWidth: 250,
        column: [{
          label: this.$t(`cip.plat.intf.serviceType.field.name`),
          prop: 'name',
          cell: true,
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.plat.intf.serviceType.field.name`),
              trigger: 'blur'
            }
          ]
        }, {
          label: this.$t(`cip.plat.intf.serviceType.field.code`),
          prop: 'code',
          cell: true
        },]
      },
      type: 'view',
      dataForm: {},
    }
  },
  computed: {
    gridRowBtn() {
      return [
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "button",
          icon: ""
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-remove",
          type: "button",
          icon: ""
        },
      ]
    },
    subTableheadBtnOptions() {
      return [
        {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        }
      ]
    },
    treeDialogOption() {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [{
          label: this.$t(`cip.plat.intf.serviceType.title.parentCate`),
          prop: 'parentId',
          type: 'tree',
          dicUrl: '/api/sinoma-interface/serviceType/tree?parentId=' + this.dataForm.id,
          props: {
            label: 'name',
            value: 'id'
          },
          span: 12,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.intf.serviceType.field.name`),
        }, {
          label: this.$t(`cip.plat.intf.serviceType.field.name`),
          prop: 'name',
          span: 12,
        }, {
          label: this.$t(`cip.plat.intf.serviceType.field.code`),
          prop: 'code',
          span: 12,
        }
        ]
      }
    },
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t('cip.plat.intf.serviceType.field.code'),
          prop: 'code',
          span: 8,
          disabled: ['view', 'edit'].includes(this.type),
          labelWidth: 130,
          dataType: "string",
          placeholder: this.$t(`cip.plat.intf.serviceType.field.code`),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.intf.serviceType.field.code'),
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t('cip.plat.intf.serviceType.field.name'),
          prop: 'name',
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.intf.serviceType.field.name`),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.plat.intf.serviceType.field.name`),
              trigger: "blur"
            },
            {
              pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
              message: this.$t("cip.cmn.rule.deleteWarning"),
            }

          ],
        },
        {
          label: this.$t('cip.plat.intf.serviceType.field.ip'),
          prop: 'ip',
          span: 8,
          labelWidth: 130,
          maxlength: 100,
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.intf.serviceType.field.ip`),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.intf.serviceType.field.ip'),
              trigger: "blur"
            },
            {
              pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
              message: this.$t("cip.cmn.rule.deleteWarning"),
            }
          ],
        },
        {
          label: this.$t('cip.plat.intf.serviceType.field.type'),
          prop: 'type',
          labelWidth: 130,
          type: 'select',
          maxlength: 100,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=service_type",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          span: 8,
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.intf.serviceType.field.type`),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.intf.serviceType.field.type'),
              trigger: "blur"
            },
            {
              pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
              message: this.$t("cip.cmn.rule.deleteWarning"),
            }
          ],
          control: (val) => {
            if (val != 1) {
              console.log(val)
              return {
                port: {
                  display: false,
                  rules: [
                    {
                      required: true,
                      message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.intf.serviceType.field.port'),
                      trigger: "blur"
                    },
                    {
                      pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
                      message: this.$t("cip.cmn.rule.deleteWarning"),
                    }
                  ],
                },
              }
            } else {
              return {
                port: {
                  display: true,
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              }
            }
          }
        },
        {
          label: this.$t('cip.plat.intf.serviceType.field.port'),
          prop: 'port',
          labelWidth: 130,
          maxlength: 100,
          dataType: 'string',
          display: true,
          readonly: !['edit', 'add'].includes(this.type),
        },
      ]
    },
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  methods: {
    subTableAdd() {
      this.form = {}
      this.showTreeDialog = true
    },
    headRemove() {
      if (this.selectionList.length == 0) {
        this.$message.warning("cip.cmn.msg.warning.selectWarning")
        return false
      }
      var ids = this.selectionList.map(item => item.id);
      remove(ids.join(',')).then(() => {
        tree({"parentId": this.dataForm.id}).then(res => {
          this.data = res.data.data
        })
        this.$message({
          type: "success",
          message: this.$t('cip.cmn.msg.success.operateSuccess')
        });
      });
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          tree({"parentId": this.dataForm.id}).then(res => {
            this.data = res.data.data
          })
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    selectionChange(list) {
      this.selectionList = list;
      console.log("aaa", this.selectionList)
    },
    treeMenuEdit(data) {
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
      this.form = data;
    },
    treeNodeSave(data, done) {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          if (!this.form.parentId) this.form.parentId = this.dataForm.id
          saveOrUpdateTree(this.form).then(res => {
            const {msg, data} = res.data;
            this.treeReadOnlyFlag = true;
            if (res.data.code == 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(msg);
            }
            tree({"parentId": this.dataForm.id}).then(res => {
              this.data = res.data.data
            })
            this.showTreeDialog = false;
          }).catch(err => {
            console.log(err)
            done()
          })
        }
      })
    },
    // 新增左侧树节点
    handleTreeNodeSave() {
      this.$refs.addForm.submit();
    },
    rowSave(form, done) {
      if (!this.dataForm.id) {
        this.$message.warning('请保存系统信息')
      }
      form.parentId = this.dataForm.id
      update(form).then(res => {
        console.log(res, '12313123')
      })
      done()
    },
    addUpdate(form, index, done, loading) {
      this.$message.success('模拟网络请求')
      setTimeout(() => {
        this.$message.success('关闭按钮等待')
        loading()
      }, 1000)
      setTimeout(() => {
        this.$message.success(
          '编辑数据' + JSON.stringify(form) + '数据序号' + index
        )
        done()
      }, 2000)
    },

    initData() {
      getDetail(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
      })
      tree({"parentId": this.dataForm.id}).then(res => {
        this.data = res.data.data
      })
    },
    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.$refs.formLayout.dataForm.type != 1) {
            this.$refs.formLayout.dataForm.port = null;
          }
          this.$loading()
          update({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$loading().close()
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }

}
</script>

<style scoped lang="scss">
::v-deep .serviceType .avue-crud .el-table {
  height: calc(100vh - 370px) !important;
  max-height: calc(100vh - 370px) !important;
}
</style>
